import React, {useCallback, useState} from "react"
import axios from 'axios';
import { useForm } from 'react-hook-form';
import RecaptchaComponent from "@components/recaptcha";

// 言語用Jsonファイル(言語増えたら下に追加、※1に条件分岐追加)
import langEn from "@content/locales/en/forms.json";
import langJa from "@content/locales/ja/forms.json";

// Import SVG
import snsIcon_x from '@images/sns_icon_x.svg';
import sns_icon_insta from '@images/sns_icon_insta.svg';

export default function FormsParts( props ){

  // 言語設定
  let postLang;
  (props.langType === 'ja') ? postLang = langJa : postLang = langEn; // ※1：使用言語設定

  // 言語別URL先頭設定
  let langUrl;
  (props.langType === 'en') ? langUrl = '/' : langUrl = '/' + props.langType + '/';
  let thanksPath = langUrl + "thanks/";

  // WordPress URL
  const WEBSITE_URL = 'https://h4lwagdvk9bmpthz.miltonmarkets.com';
  const form_id = postLang['form_id']

  // 子コンポーネントからrecapデータを受け取ってエラー判定
  let recapStatus = 'error'; // 初期値
  let addClass = 'hidden'; // 初期値
  const [recapCheck, setRecapStatus] = useState("");
  const handleRecap = (recapData) => {
    (recapData !== null) ? recapStatus = 'clear' : recapStatus = 'error'; // submitのタイミングでrecap判定
    setRecapStatus(recapStatus);
  };

  // submitのタイミングでrecapのステータス次第で注意書きのクラスを変更
  const [addCSS, setData] = useState(addClass);
  function handleUpdate() {
    (recapCheck === 'clear') ? addClass = 'hidden' : addClass = '';
    setData(addClass);
  }

  const { register, handleSubmit } = useForm();
  const onSubmit = useCallback(data => {
    // contactform7 form data
    const bodyFormData = new FormData();
    bodyFormData.set(postLang["category_1"], data[postLang["category_1"]]);
    bodyFormData.set(postLang["category_2"], data[postLang["category_2"]]);
    bodyFormData.set(postLang["category_3"], data[postLang["category_3"]]);
    bodyFormData.set(postLang["category_4"], data[postLang["category_4"]]);
    bodyFormData.set(postLang["category_5"], data[postLang["category_5"]]);
    bodyFormData.set(postLang["category_6"], data[postLang["category_6"]]);

    //mailアドレス検証
    var emailElem = bodyFormData.get(postLang["category_3"]);
    const emailpattern =  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    var checkEmail ;
    var checkEmailError ;
    if(!emailpattern.test(emailElem)) {
      checkEmail = document.getElementById('checkEmailId');
      checkEmail.classList.remove("hidden")
      checkEmailError = "error" ;
    }else{
      checkEmail = document.getElementById('checkEmailId');
      checkEmail.classList.add("hidden")
      checkEmailError = "clear" ;
    }
    
    //電話番号検証
    var telElem = bodyFormData.get(postLang["category_5"]);
    const telpattern =  /^(0[-0-9]{9,12}|\+[-0-9]{12,15})$/;
    var checkTel ;
    var checkTelError ;
    if(!telpattern.test(telElem)) {
      checkTel = document.getElementById('checkTelId');
      checkTel.classList.remove("hidden")
      checkTelError = "error";
    }else{
      checkTel = document.getElementById('checkTelId');
      checkTel.classList.add("hidden")
      checkTelError = "clear";
    }

    // recap検証
    // let checkRecapError;
    // let recaptest; 
    // (recaptest !== null) ? checkRecapError = 'clear' : checkRecapError = 'error';

    // submit進行
    if ( checkEmailError === "clear" && checkTelError === "clear" && recapCheck === "clear") {
      axios({
        method: 'post',
        url:`${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${form_id}/feedback`,
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response =>{
        window.location.href = thanksPath;
        console.log('成功');
      }).catch(error => {
        console.log('失敗');
      });
    } else if ( checkEmailError === "error" ) {
      document.getElementById("your-email").focus();
    } else if ( checkTelError === "error" ) {
      document.getElementById("your-phone").focus();
    } else if ( recapStatus === "error" ) {
      // フォーカス未実装
      console.log('チェックなし')
      console.log(recapStatus)
    } else {
    }
  },[form_id,postLang,recapCheck]);

  const downArrow = <svg className="w-6 h-6 relative top-14 right-4 text-black z-0" fill="none" stroke ="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>

  const category1 = () =>{
    var data = () =>{
      var l = []
      for(var i=0; i<3; i++){
        l.push(
        <option className="relative" value={postLang['category_1_options_'+ String(i+1)]}>{postLang['category_1_options_'+String(i+1)]}</option>)
      }
      return l
    };
    var value =
      <select
      id={postLang['category_1']} {...register(postLang['category_1'], {required: true})}
      className="w-full p-5  mb-8 focus:outline-[#067CFA] appearance-none"
      >
        {data()}
      </select>
    return value
  };

  const category6 = () =>{
    var data = () =>{
      var l = []
      for(var i=0; i<8; i++){
        l.push( <option value={postLang['category_6_options_'+ String(i+1)]}>{postLang['category_6_options_'+String(i+1)]}</option>)
      }
      return l
    };
    var value =
      <select
      id={postLang['category_6']} {...register(postLang['category_6'], {required: true})}
      className="w-full p-5  focus:outline-[#067CFA] appearance-none"
      >
        {data()}
      </select>
    return value
  };

return(
  <>
    <h2 className="border-l-2 border-[#067CFA] py-2 px-3 mb-6">{postLang['form_title_1']}</h2>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <label htmlFor={postLang['category_1']} className="w-full flex justify-between text-[#067CFA] text-sm mb-2 relative">
          {postLang['category_1_title']}
          {downArrow}
        </label>
        {category1()}
      </div>
      <div>
        <label
        htmlFor={postLang['category_2']}
        className="w-full block text-[#067CFA] text-sm mb-2"
        >{postLang['category_2_title']}&nbsp;{postLang['required']}
        </label>
        <input
        id={postLang['category_2']} {...register(postLang['category_2'], {required: true})} 
        placeholder={postLang['category_2_pholder']}
        className="w-full p-5  mb-8 focus:outline-[#067CFA] appearance-none"
        maxLength="40"
        />
      </div>
      <div>
        <label
        htmlFor={postLang['category_3']}
        className="w-full block text-[#067CFA] text-sm mb-2"
        >{postLang['category_3_title']}&nbsp;{postLang['required']}
        </label>
        <input
        id={postLang['category_3']} {...register(postLang['category_3'], {required: true})}
        placeholder={postLang['category_3_pholder']}
        className="w-full p-5  focus:outline-[#067CFA] appearance-none"
        maxLength="50"
        />
        <div className="mb-3 h-5">
          <div className="text-[#067CFA] text-right text-sm hidden" id="checkEmailId">{postLang['category_3_annotation']}</div>
        </div>
      </div>

      <div>
        <label
        htmlFor={postLang['category_4']}
        className="w-full block text-[#067CFA] text-sm mb-2"
        >{postLang['category_4_title']}&nbsp;{postLang['required']}
        </label>
        <input
        id={postLang['category_4']} {...register(postLang['category_4'], {required: true})}
        placeholder={postLang['category_4_pholder']}
        className="w-full p-5  mb-8 focus:outline-[#067CFA] appearance-none"
        maxLength="40"
        />
      </div>
      <div>
        <label
        htmlFor={postLang['category_5']}
        className="w-full block text-[#067CFA] text-sm mb-2"
        >{postLang['category_5_title']}&nbsp;{postLang['required']}
        </label>
        <input
        id={postLang['category_5']} {...register(postLang['category_5'], {required: true})}
        placeholder={postLang['category_5_pholder']}
        className="w-full p-5  focus:outline-[#067CFA] appearance-none"
        maxLength={20}
        />
        <div className="test" style={{height: 80}}>
          <div className="text-[#067CFA] text-right text-sm hidden" id="checkTelId">{postLang['category_5_annotation']}</div>
        </div>
      </div>

      <h2 className="border-l-2 border-[#067CFA] py-2 px-3 mb-6">{postLang['form_title_2']}</h2>
      <div>
        <label
        htmlFor={postLang['category_6']}
        className="w-full block text-[#067CFA] text-sm mb-2 flex justify-between"
        >{postLang['category_6_title']}
          {downArrow}
        </label>
        {category6()}
      </div>

      {/* reCAPTCHA */}
      <div className="mt-10">
        <RecaptchaComponent onData={handleRecap}/>
        <p className={'text-[14px] text-[red] ' + addCSS}>※チェックを付けてください</p>
      </div>

      <div className="my-12">
        <button onClick={handleUpdate} className="rounded block my-2 px-14 py-4 text-white text-base font-bold bg-[#047DFB] hover:bg-[#047DFB] " type="submit">{postLang['submit']}</button>
      </div>
    </form>
  </>
  );
}